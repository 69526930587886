exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-account-management-tsx": () => import("./../../../src/pages/projects/account-management.tsx" /* webpackChunkName: "component---src-pages-projects-account-management-tsx" */),
  "component---src-pages-projects-analytics-dashboard-tsx": () => import("./../../../src/pages/projects/analytics-dashboard.tsx" /* webpackChunkName: "component---src-pages-projects-analytics-dashboard-tsx" */),
  "component---src-pages-projects-custom-form-builder-tsx": () => import("./../../../src/pages/projects/custom-form-builder.tsx" /* webpackChunkName: "component---src-pages-projects-custom-form-builder-tsx" */),
  "component---src-pages-projects-hacker-apps-tsx": () => import("./../../../src/pages/projects/hacker-apps.tsx" /* webpackChunkName: "component---src-pages-projects-hacker-apps-tsx" */),
  "component---src-pages-projects-scheduling-tsx": () => import("./../../../src/pages/projects/scheduling.tsx" /* webpackChunkName: "component---src-pages-projects-scheduling-tsx" */),
  "component---src-pages-projects-surgery-scheduling-calendar-tsx": () => import("./../../../src/pages/projects/surgery-scheduling-calendar.tsx" /* webpackChunkName: "component---src-pages-projects-surgery-scheduling-calendar-tsx" */),
  "component---src-pages-projects-thematic-tsx": () => import("./../../../src/pages/projects/thematic.tsx" /* webpackChunkName: "component---src-pages-projects-thematic-tsx" */)
}

